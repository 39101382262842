.landing .landingWrapper {
  height: 100%;
}
.landing .landingWrapper .landingContainer {
  margin: 2% 5% 0% 5%;
  margin-top: 3%;
  height: 100%;
}
.landing .landingWrapper .landingContainer .landingTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
@media (max-width: 480px) {
  .landing .landingWrapper .landingContainer .landingTop {
    flex-direction: column;
    align-items: unset !important;
  }
}
@media (min-width: 1380px) {
  .landing .landingWrapper .landingContainer .landingTop {
    margin-bottom: 70px;
    margin-top: 70px;
  }
}
.landing .landingWrapper .landingContainer .landingTop .landingtexts {
  flex: 1;
  height: 100%;
}
@media (max-width: 480px) {
  .landing .landingWrapper .landingContainer .landingTop .landingtexts {
    width: 100%;
    height: unset;
  }
}
.landing .landingWrapper .landingContainer .landingTop .landingtexts .lndingtextswrapper .ct_lnd_text {
  font-size: 5.375rem;
  line-height: 5rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  font-family: "VolteRounded-Bold", Apercu, Roboto;
  font-weight: 800 !important;
  margin-bottom: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media (max-width: 1900px) {
  .landing .landingWrapper .landingContainer .landingTop .landingtexts .lndingtextswrapper .ct_lnd_text {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (max-width: 480px) {
  .landing .landingWrapper .landingContainer .landingTop .landingtexts .lndingtextswrapper .ct_lnd_text {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.landing .landingWrapper .landingContainer .landingTop .landingtexts .lndingtextswrapper .ct_lnd_text_p {
  font-size: 1.375rem;
  line-height: 1.64;
  letter-spacing: -0.015em;
  margin-bottom: 3rem;
  color: var(--text-color);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media (max-width: 1900px) {
  .landing .landingWrapper .landingContainer .landingTop .landingtexts .lndingtextswrapper .ct_lnd_text_p {
    font-size: 1rem;
  }
}
.landing .landingWrapper .landingContainer .landingTop .landingtexts .lndingtextswrapper .cta_get_started {
  margin: 20px 0;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  font-family: Roboto, sans-serif;
  border-radius: 23px;
  color: #fff !important;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5rem;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s;
  border: none;
  outline: none;
  cursor: pointer;
}
.landing .landingWrapper .landingContainer .landingTop .landingImage {
  width: 50%;
  height: 100%;
}
@media (max-width: 480px) {
  .landing .landingWrapper .landingContainer .landingTop .landingImage {
    width: 100%;
    height: unset;
    margin: 60px 0;
  }
}
.landing .landingWrapper .landingContainer .landingTop .landingImage .lndingImageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1700px) {
  .landing .landingWrapper .landingContainer .landingTop .landingImage .lndingImageWrapper img {
    width: 85%;
    margin: 0 auto;
  }
}
.landing .landingWrapper .landingContainer .landingBottom {
  height: 100px;
  margin-top: 50px;
}
@media (min-width: 1380px) {
  .landing .landingWrapper .landingContainer .landingBottom {
    margin-bottom: 40px;
  }
}
.landing .landingWrapper .landingContainer .landingBottom .landingbtmpartners {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.landing .landingWrapper .landingContainer .landingBottom .landingbtmpartners .lndngpartnerdk {
  height: 40px;
  width: 40px;
  margin-right: 40px;
}
.landing .landingWrapper .landingContainer .landingBottom .landingbtmpartners .lndngpartnerTenAfric {
  height: 40px;
  width: 120px;
  fill: var(--text-color);
}
.landing .landingWrapper .landingContainer .landingBottom .landingbtmtxt {
  text-align: center;
  margin-top: 10px;
}
.landing .landingWrapper .landingContainer .landingBottom .landingbtmtxt p {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 32px;
  font-family: "VolteRounded-Bold";
  color: var(--text-color);
  max-width: initial;
}/*# sourceMappingURL=landing.css.map */