.landing {
  .landingWrapper {
    height: 100%;
    .landingContainer {
      margin: 2% 5% 0% 5%;
      margin-top: 3%;
      height: 100%;
      .landingTop {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
        @media (max-width: 480px) {
          flex-direction: column;
          align-items: unset !important;
        }
        @media (min-width: 1380px) {
          margin-bottom: 70px;
          margin-top: 70px;
        }
        .landingtexts {
          flex: 1;
          height: 100%;
          @media (max-width: 480px) {
            width: 100%;
            height: unset;
          }
          .lndingtextswrapper {
            .ct_lnd_text {
              font-size: 5.375rem;
              line-height: 5rem;
              letter-spacing: -0.05rem;
              color: var(--text-color);
              font-family: 'VolteRounded-Bold', Apercu, Roboto;
              font-weight: 800 !important;
              margin-bottom: 2rem;
              user-select: none;
              @media (max-width: 1900px) {
                font-size: 4rem;
                line-height: 4rem;
              }
              @media (max-width: 480px) {
                font-size: 3rem;
                line-height: 3rem;
              }
            }
            .ct_lnd_text_p {
              font-size: 1.375rem;
              line-height: 1.64;
              letter-spacing: -0.015em;
              margin-bottom: 3rem;
              color: var(--text-color);
              user-select: none;
              @media (max-width: 1900px) {
                font-size: 1rem;
              }
            }
            .cta_get_started {
              margin: 20px 0;
              background: linear-gradient(
                to bottom right,
                #14a9ff,
                #0088f0
              ) !important;
              font-family: Roboto, sans-serif;
              border-radius: 23px;
              color: #fff !important;
              font-size: 1.5rem;
              font-weight: 700;
              line-height: 1.5rem;
              padding: 10px 20px;
              text-align: center;
              text-transform: uppercase;
              transition: 0.4s;
              border: none;
              outline: none;
              cursor: pointer;
            }
          }
        }
        .landingImage {
          width: 50%;
          height: 100%;
          @media (max-width: 480px) {
            width: 100%;
            height: unset;
            margin: 60px 0;
          }
          .lndingImageWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              @media (max-width: 1700px) {
                width: 85%;
                margin: 0 auto;
              }
            }
          }
        }
      }
      .landingBottom {
        height: 100px;
        margin-top: 50px;
        @media (min-width: 1380px) {
          margin-bottom: 40px;
        }
        .landingbtmpartners {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          .lndngpartnerdk {
            height: 40px;
            width: 40px;
            margin-right: 40px;
          }
          .lndngpartnerTenAfric {
            height: 40px;
            width: 120px;
            fill: var(--text-color);
          }
        }
        .landingbtmtxt {
          text-align: center;
          margin-top: 10px;
          p {
            text-transform: uppercase;
            font-size: 0.9rem;
            font-weight: 800;
            line-height: 32px;
            font-family: 'VolteRounded-Bold';
            color: var(--text-color);
            max-width: initial;
          }
        }
      }
    }
  }
}
