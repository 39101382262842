.aboutus {
  /*=== About us header===*/
  /*=== Our Mission ===*/
  /* ======frequently asked questions stylings====== */
}
.aboutus .about-header {
  margin: 5% 0;
  margin-top: 2% !important;
}
.aboutus .about-header .about-header-wrapper {
  text-align: center;
}
.aboutus .about-header .about-header-wrapper h2 {
  font-size: 5.375rem;
  line-height: 5rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  font-family: "VolteRounded-Bold", Volte Rounded;
  font-weight: 800 !important;
  margin-bottom: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: center;
}
@media (max-width: 1900px) {
  .aboutus .about-header .about-header-wrapper h2 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (max-width: 480px) {
  .aboutus .about-header .about-header-wrapper h2 {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.aboutus .mission {
  margin: 5%;
}
.aboutus .mission .mission-wrapper {
  display: flex;
}
@media (max-width: 480px) {
  .aboutus .mission .mission-wrapper {
    flex-direction: column;
  }
}
.aboutus .mission .mission-wrapper .mission-images {
  width: 50%;
}
@media (max-width: 480px) {
  .aboutus .mission .mission-wrapper .mission-images {
    width: 100%;
  }
}
.aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper {
  position: relative;
  height: 400px;
  width: 400px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 0% 50% 50% 50%;
}
@media (max-width: 480px) {
  .aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper {
    height: 250px;
    width: 250px;
  }
}
.aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper .gridImgWrapItem {
  background-image: url("../../assets/images/unsplash/jason-goodman-square.jpg"), url("../../assets/images/unsplash/jason-goodman-square.jpg"), url("../../assets/images/unsplash/jason-goodman-square.jpg"), url("../../assets/images/unsplash/jason-goodman-square.jpg");
  background-size: 200%;
  background-repeat: no-repeat;
  height: 200px;
  width: 200px;
}
@media (max-width: 480px) {
  .aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper .gridImgWrapItem {
    height: 125px;
    width: 125px;
  }
}
.aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper .gridImgWrapItem:nth-child(1) {
  background-position: 0px 0px;
}
.aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper .gridImgWrapItem:nth-child(2) {
  background-position: -200px 0px;
}
.aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper .gridImgWrapItem:nth-child(3) {
  background-position: 0px -200px;
}
.aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper .gridImgWrapItem:nth-child(4) {
  background-position: -200px -200px;
}
@media (max-width: 480px) {
  .aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper .gridImgWrapItem:nth-child(1) {
    background-position: 0px 0px;
  }
  .aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper .gridImgWrapItem:nth-child(2) {
    background-position: -125px 0px;
  }
  .aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper .gridImgWrapItem:nth-child(3) {
    background-position: 0px -125px;
  }
  .aboutus .mission .mission-wrapper .mission-images .miss-image-wrapper .missGridWrapper .gridImgWrapItem:nth-child(4) {
    background-position: -125px -125px;
  }
}
.aboutus .mission .mission-wrapper .mission-texts {
  width: 50%;
}
@media (max-width: 480px) {
  .aboutus .mission .mission-wrapper .mission-texts {
    width: 100%;
  }
}
.aboutus .mission .mission-wrapper .mission-texts .sec-text {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  background: 0px 0px;
  border: 1px solid rgba(19, 20, 38, 0.16);
  border-radius: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background: linear-gradient(94.67deg, #9935e8 0, #e85535 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.aboutus .mission .mission-wrapper .mission-texts h1 {
  color: var(--text-color);
  font-family: "VolteRounded-Bold", Volte Rounded;
  font-size: 1.7rem;
  line-height: 1.7rem;
  margin: 0.5rem 0;
}
.aboutus .mission .mission-wrapper .mission-texts p {
  color: var(--text-color);
  font-size: 1.2rem;
  line-height: 1.2rem;
}
.aboutus .faqmainSection {
  margin: 4rem 0;
}
.aboutus .faqmainSection .faqsection .faqcontainer {
  margin: 0 15%;
}
@media (max-width: 480px) {
  .aboutus .faqmainSection .faqsection .faqcontainer {
    margin: 0 2%;
  }
}
.aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper {
  padding: 6rem;
  padding-bottom: 4rem;
  border-radius: 23px;
  background: linear-gradient(to bottom right, rgb(11, 30, 41), rgb(9, 20, 26)) !important;
  transition: 0.3s;
}
@media (max-width: 480px) {
  .aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper {
    padding: 2rem;
    padding-bottom: 2rem;
  }
}
.aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqtitle {
  margin-bottom: 40px;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: #f6f6f6;
  text-transform: uppercase;
}
@media (max-width: 480px) {
  .aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqtitle {
    margin-bottom: 20px;
  }
}
.aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdroptitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
}
.aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdroptitle .faqText,
.aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdroptitle i {
  color: #f6f6f6;
  font-size: 20px;
  font-weight: 700;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  letter-spacing: 1.4;
}
.aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdroptitle .angleDown {
  transform: rotate(90deg);
  display: unset;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdropcontents {
  display: none;
}
.aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdropcontents .faqdroptexts {
  color: #f6f6f6;
  line-height: 1.5;
  margin-bottom: 10px;
}
.aboutus .faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqShow {
  display: unset;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}/*# sourceMappingURL=about.css.map */