.services .servicesContainer {
  margin: 0 5%;
}
.services .servicesContainer .servicesWrapper {
  padding-top: 2%;
}
.services .servicesContainer .servicesWrapper .servicesHeader {
  font-size: 5.375rem;
  line-height: 5rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  font-family: "VolteRounded-Bold", Volte Rounded;
  font-weight: 800 !important;
  margin-bottom: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: center;
}
@media (max-width: 480px) {
  .services .servicesContainer .servicesWrapper .servicesHeader {
    margin-bottom: 10px;
  }
}
@media (max-width: 1900px) {
  .services .servicesContainer .servicesWrapper .servicesHeader {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (max-width: 480px) {
  .services .servicesContainer .servicesWrapper .servicesHeader {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.services .servicesContainer .servicesWrapper .serviceIntrowrapper {
  width: 60%;
  margin: 0 auto;
}
@media (max-width: 480px) {
  .services .servicesContainer .servicesWrapper .serviceIntrowrapper {
    width: 90%;
  }
}
.services .servicesContainer .servicesWrapper .serviceIntrowrapper .serviceIntro {
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
}
@media (max-width: 480px) {
  .services .servicesContainer .servicesWrapper .serviceIntrowrapper .serviceIntro {
    font-size: 0.9rem;
    line-height: 0.9rem;
  }
}
.services .servicesContainer .servicesWrapper .servCardsFlex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 4% 0;
  position: relative;
}
@media (max-width: 480px) {
  .services .servicesContainer .servicesWrapper .servCardsFlex {
    margin: 3% 0;
    flex-direction: column;
  }
}
.services .servicesContainer .servicesWrapper .servCardsFlex .servCard {
  padding: 10px 20px;
  width: 40%;
  height: 250px;
  border-radius: 10px;
  background-color: var(--background-modals);
  box-shadow: rgba(50, 50, 93, 0.2509803922) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border: 1px solid rgba(50, 50, 93, 0.2509803922);
}
@media (max-width: 480px) {
  .services .servicesContainer .servicesWrapper .servCardsFlex .servCard {
    width: 90%;
    height: auto;
    margin-bottom: 10px;
  }
}
.services .servicesContainer .servicesWrapper .servCardsFlex .servCard h2 {
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  font-family: "VolteRounded-Bold", Volte Rounded;
  font-weight: 800 !important;
  margin: 1rem 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media (max-width: 1900px) {
  .services .servicesContainer .servicesWrapper .servCardsFlex .servCard h2 {
    font-size: 3rem;
    line-height: 3rem;
  }
}
@media (max-width: 480px) {
  .services .servicesContainer .servicesWrapper .servCardsFlex .servCard h2 {
    font-size: 2rem;
    line-height: 2rem;
  }
}
.services .servicesContainer .servicesWrapper .servCardsFlex .servCard p {
  font-size: 1.2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  line-height: 1.2rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  margin-bottom: 20px;
}
.services .servicesContainer .servicesWrapper .servCardsFlex .servCard button {
  all: unset;
  position: absolute;
  bottom: 20px;
  padding: 10px 30px;
  background-color: #fff;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border-radius: 43px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin-right: 15px;
  font-family: "VolteRounded-Bold" !important;
}
@media (max-width: 480px) {
  .services .servicesContainer .servicesWrapper .servCardsFlex .servCard button {
    margin-right: unset !important;
    position: unset;
  }
}
.services .srvfllprojDevelopment {
  position: relative;
  z-index: 20 !important;
  height: 1200px;
  overflow: hidden;
}
@media (max-width: 480px) {
  .services .srvfllprojDevelopment {
    height: 1420px;
  }
}
.services .srvfllprojDevelopment .srvfllprojDevBgImage,
.services .srvfllprojDevelopment .srvteamArgumentationBgImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: 19;
}
.services .srvfllprojDevelopment .srvfllprojDevBgImage {
  background: linear-gradient(to top, var(--bg-overlay-color), transparent), url("../../assets/images/unsplash/eng-explaining.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.services .srvfllprojDevelopment .srvteamArgumentationBgImage {
  background: linear-gradient(to top, var(--bg-overlay-color) 20%, transparent 80%), url("../../assets/images/unsplash/jason-goodman.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.services .srvfllprojDevelopment .srvfllprojDevWrp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: 21;
}
.services .srvfllprojDevelopment .srvfllprojDevWrp h1 {
  font-size: 5.375rem;
  line-height: 5rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  font-family: "VolteRounded-Bold", Volte Rounded;
  font-weight: 800 !important;
  margin: 3rem 0;
  margin-top: 4rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: center;
}
@media (max-width: 1900px) {
  .services .srvfllprojDevelopment .srvfllprojDevWrp h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (max-width: 480px) {
  .services .srvfllprojDevelopment .srvfllprojDevWrp h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.services .srvfllprojDevelopment .srvfllprojDevWrp p {
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.8rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 480px) {
  .services .srvfllprojDevelopment .srvfllprojDevWrp p {
    font-size: 1.3rem;
    line-height: 1.3rem;
    width: 90%;
  }
}
.services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap {
  margin-top: 70px;
  width: 100%;
}
.services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards {
  width: 80%;
  margin: 0 auto;
  border-radius: 15px;
  background-color: var(--background-modals);
  margin-bottom: 2rem;
  box-shadow: rgba(50, 50, 93, 0.2509803922) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border: 1px solid rgba(50, 50, 93, 0.2509803922);
  display: flex;
}
@media (max-width: 480px) {
  .services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards {
    width: 90%;
  }
}
.services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdleft {
  width: 20%;
  position: relative;
}
@media (max-width: 480px) {
  .services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdleft {
    display: none;
  }
}
.services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdleft .srcfldIc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 200px;
  fill: var(--text-color);
  margin: 0 auto;
}
.services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdright {
  flex: 1;
}
@media (max-width: 480px) {
  .services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdright {
    padding: 10px;
  }
}
.services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdright .srvlfdrighth2 {
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  font-family: "VolteRounded-Bold", Volte Rounded;
  font-weight: 800 !important;
  margin: 1.2rem 0 !important;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media (max-width: 1900px) {
  .services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdright .srvlfdrighth2 {
    font-size: 2rem;
    line-height: 2rem;
  }
}
@media (max-width: 480px) {
  .services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdright .srvlfdrighth2 {
    font-size: 2rem;
    line-height: 2rem;
    margin: 10px 0;
  }
}
.services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdright .srvlfdrightp {
  font-size: 1.2rem;
  line-height: 1.2rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  text-align: unset;
  margin: unset;
  width: unset;
}
@media (max-width: 480px) {
  .services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdright .srvlfdrightp {
    font-size: 0.9rem;
    line-height: 0.9rem;
  }
}
.services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdright span {
  padding: 5px 10px;
  border-radius: 23px;
  background-color: #afdbe4;
  margin: 10px;
  cursor: pointer;
}
.services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdright .srvfldcrdbtn {
  all: unset;
  padding: 10px 30px;
  background-color: #fff;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border-radius: 43px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin-right: 15px;
  font-family: "VolteRounded-Bold" !important;
  margin: 10px 0;
  margin-top: 20px;
}
@media (max-width: 480px) {
  .services .srvfllprojDevelopment .srvfllprojDevWrp .fldevcardFlexWrap .srvfldcards .srvlfdright .srvfldcrdbtn {
    margin-right: unset !important;
    bottom: 80px;
  }
}/*# sourceMappingURL=services.css.map */