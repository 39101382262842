.erecruit {
          .application_form_bottom {
        padding-top: 2rem;
        .application_form_title {
          font-size: 3rem;
          margin-bottom: 20px;
          color: var(--text-color);
        }
        .application_progress_indicator {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          .active-ind {
            span {
              background-color: #2ac6ff !important;
            }
          }
          .ind_sec_item {
            text-align: center;
            cursor: pointer;
            span {
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              text-align: center;
              line-height: 20px;
              color: white;
              font-weight: bold;
              background-color: var(--text-color);
              color: var(--text-color-inv);
            }
            p {
              margin-top: 5px !important;
              color: var(--text-color);
              &:nth-child(3) {
                margin-top: 1px !important;
              }
            }
            span,
            p {
              text-align: center;
              margin: 0 auto;
              font-size: 0.8rem;
            }
          }
        }
        .application_form_item {
        }
        .apply_job_form {
          .aplication_form_section {
            .appl-page {
              min-height: 100vh;
              position: relative;
              @media (max-width: 480px) {
                margin: 0 5px;
              }
              .appl-page-wrapper {
                padding: 20px;
                //margin: 0 auto;
                height: min-content;
                @media (max-width: 480px) {
                  width: unset;
                }
                .formNotifySucc {
                  padding: 10px;
                  width: 95%;
                  color: var(--text-color);
                  background-color: #34ff9a;
                  border-left: 7px solid #009431;
                  margin-bottom: 20px;
                }
                .formNotify {
                  padding: 10px;
                  width: 95%;
                  color: var(--text-color);
                  background-color: #ff556b;
                  border-left: 7px solid #940000;
                  margin-bottom: 20px;
                }
                .applFormSec {
                  padding-top: 1rem;
                  margin-left: 15px;
                  @media (max-width: 480px) {
                    margin-left: -10px;
                    padding-top: 1rem;
                  }
                  form {
                    h3 {
                      color: var(--text-color);
                    }
                    .appl-group {
                      margin-bottom: 40px;
                      .user-input-wrp {
                        position: relative;
                        width: 94%;
                        margin: 5px 0px;
                      }
                      .user-input-wrp .inputText {
                        width: 100%;
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 200px;
                        outline: none;
                        border: 1px solid var(--text-color);
                        padding: 10px 13px;
                        box-shadow: none !important;
                        border-radius: 5px;
                        background-color: #e7f1ff;
                        display: unset !important;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                      }
                      .user-input-wrp .inputText:not([value='']) {
                        background-color: rgb(255, 219, 166);
                      }
                      .user-input-wrp .floating-label {
                        position: absolute;
                        pointer-events: none;
                        top: 27px;
                        left: 10px;
                        transition: 0.2s ease all;
                        opacity: 0.5;
                        color: rgb(95, 95, 95);
                      }
                      .user-input-wrp
                        .inputText:not([value=''])
                        ~ .floating-label,
                      .user-input-wrp .inputText:focus ~ .floating-label {
                        top: 0px;
                        left: 8px;
                        font-size: 13px;
                        opacity: 1;
                        color: var(--text-color);
                      }
                      // #id-input{
                      //     border: 2px solid #ff5b5b;
                      // }
                      // #id-err{
                      //     font-size: 12px;
                      //     color: red;
                      //     margin-left: 10px;
                      // }
                      .appl-gr-flex {
                        width: 97%;
                        display: flex;
                        justify-content: space-between;
                        .applPut {
                          width: 46%;
                        }
                      }
                      .input-field-row {
                        display: flex;
                        margin-bottom: 22px;
                        -webkit-box-pack: justify;
                        justify-content: space-between;
                        margin: 15px 0px;
                        .input-file-row {
                          width: 98%;
                          position: relative;
                          @media (max-width: 480px) {
                            width: 100%;
                          }
                          .project_brief {
                            display: flex;
                            padding: 18px 20px;
                            -webkit-box-align: center;
                            align-items: center;
                            -webkit-box-pack: justify;
                            justify-content: space-between;
                            cursor: pointer;
                            border: 1px dashed var(--text-color);
                            border-radius: 5px;
                            .file-applent {
                              display: flex;
                              -webkit-box-align: center;
                              align-items: center;
                              position: relative;
                              width: 80%;
                              @media (max-width: 480px) {
                                width: 100%;
                              }
                              .icon {
                                margin-right: 15px;
                                img {
                                  max-width: 100%;
                                  height: auto;
                                }
                              }
                              .applent-label {
                                font-size: 13px;
                                letter-spacing: -0.17px;
                                line-height: 22px;
                                color: var(--text-color);
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                width: 800px;
                                overflow-x: scroll;
                                &::-webkit-scrollbar {
                                  scrollbar-color: rgb(35, 42, 46);
                                  width: 5px;
                                  max-height: 3px;
                                  border-radius: 23px;
                                  background-color: #ffffff00;
                                }
                                .filenames-list {
                                  padding: 8px 15px;
                                  border-radius: 23px;
                                  font-size: 0.9rem;
                                  text-decoration: none;
                                  color: #eaeaea;
                                  background-color: #505050;
                                  margin-right: 3px;
                                  width: min-content;
                                  white-space: nowrap;
                                  &:hover {
                                    background-color: #797979;
                                  }
                                }
                              }
                            }
                            .row-wrap {
                              display: flex;
                              -webkit-box-align: center;
                              align-items: center;
                              @media (max-width: 480px) {
                                display: none;
                              }
                              .button {
                                background: #2ac6ff;
                                border-radius: 4px;
                                padding: 9px 23px 11px;
                                font-size: 16px;
                                letter-spacing: 1.6px;
                                color: rgb(255, 255, 255);
                                text-transform: uppercase;
                                font-weight: 500;
                                z-index: unset !important;
                              }
                            }
                          }
                          .file-field {
                            height: 100%;
                            opacity: 0;
                            overflow: hidden;
                            position: absolute;
                            width: 100%;
                            top: 0px;
                            left: 0px;
                            cursor: pointer;
                          }
                        }
                      }
                    }
                    .appl-page-btn {
                      border-radius: 4px;
                      padding: 13px 54px 15px;
                      background-color: #2ac6ff;
                      border: 1px solid #2ac6ff;
                      position: relative;
                      overflow: hidden;
                      z-index: 1;
                      text-align: center;
                      line-height: 19px;
                      cursor: pointer;
                      font-size: 16px;
                      font-weight: 600;
                      letter-spacing: 1.6px;
                      text-transform: uppercase;
                      color: rgb(255, 255, 255);
                      -webkit-box-align: center;
                      align-items: center;
                      transition: color 0.15s ease-in-out 0s;
                      display: inline-block;
                      margin-bottom: 2rem;
                      &:hover {
                        color: rgb(109, 216, 255);
                        background-color: rgb(233, 233, 233);
                        transition: 0.3s;
                      }
                    }
                  }
                }
              }
              .appl_textuals {
                width: 40%;
                padding-top: 4rem;
                height: 650px;
                .appl_textuals_wrapper {
                  position: sticky;
                  top: 20px;
                  .appl_t_high {
                    width: 80%;
                    margin: 0 auto;
                    padding-bottom: 20px;
                    &:nth-child(1) {
                      border-bottom: 1px solid #2ac6ff;
                    }
                    .appl_ic_wrapper {
                      margin: 0 auto;
                      height: 100px;
                      width: 200px;
                      .appl_t_ic {
                      }
                    }
                    p {
                      color: var(--text-color);
                      text-align: center;
                      a {
                        color: #006aff;
                        text-decoration: underline;
                      }
                    }
                  }
                  .appl_socials {
                    padding: 1.5rem 0;
                    justify-content: center;
                    gap: 10px;
                    .appl_soc_item {
                      &:nth-child(1) {
                        margin-bottom: -6px;
                        .appl_soc_ic {
                          height: 30px;
                          width: 30px;
                        }
                      }
                      .appl_soc_ic {
                        height: 25px;
                        width: 25px;
                        fill: var(--text-color);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
}
