.read .toc_toggler {
  position: fixed;
  top: 50%;
  right: 0;
}
.read .toc_toggler .toggler_btn {
  outline: none;
  border: none;
  background-color: #0088f0;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.read .readContainer {
  margin: 0 5%;
}
.read .readContainer .readWrapper {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
@media (max-width: 480px) {
  .read .readContainer .readWrapper {
    flex-direction: column;
  }
}
.read .readContainer .readWrapper .readContent {
  width: 70%;
  min-height: 70vh;
  position: relative;
}
@media (max-width: 480px) {
  .read .readContainer .readWrapper .readContent {
    width: 100%;
    order: 1;
  }
}
.read .readContainer .readWrapper .readContent .loadingAnim {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.read .readContainer .readWrapper .readContent .loadingAnim .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.read .readContainer .readWrapper .readContent .loadingAnim .lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0088f0;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.read .readContainer .readWrapper .readContent .loadingAnim .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.read .readContainer .readWrapper .readContent .loadingAnim .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.read .readContainer .readWrapper .readContent .loadingAnim .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.read .readContainer .readWrapper .readContent .loadingAnim .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.read .readContainer .readWrapper .readContent .readBreadCrumb {
  margin: 20px 0;
}
.read .readContainer .readWrapper .readContent .readBreadCrumb span {
  font-size: 1rem;
  margin-right: 10px;
  font-weight: 800;
  color: var(--text-color);
}
.read .readContainer .readWrapper .readContent .readBreadCrumb .arrowright {
  height: 15px;
  width: 15px;
  margin-bottom: -2px;
  margin-right: 10px;
  fill: var(--text-color);
}
.read .readContainer .readWrapper .readContent .readtitle {
  word-break: break-word;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: "VolteRounded-Bold", Volte Rounded;
  color: var(--text-color);
  text-transform: capitalize !important;
}
@media (max-width: 480px) {
  .read .readContainer .readWrapper .readContent .readtitle {
    font-size: 2rem;
  }
}
.read .readContainer .readWrapper .readContent .readSubTitle {
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: 700;
  font-family: "VolteRounded-Bold", Volte Rounded;
  text-transform: capitalize !important;
  color: var(--text-color);
}
.read .readContainer .readWrapper .readContent .readImageContainer {
  height: auto;
  width: 100%;
  margin: 30px auto;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 480px) {
  .read .readContainer .readWrapper .readContent .readImageContainer {
    height: auto;
    width: 100%;
    margin: 20px auto;
  }
}
.read .readContainer .readWrapper .readContent .readImageContainer .readImage {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.read .readContainer .readWrapper .readContent .readParagraph {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin: 0 0 15px 0;
  color: var(--text-color);
  max-width: initial;
}
.read .readContainer .readWrapper .readSidebarRight {
  width: 20%;
  transition: all 2s ease-in-out;
}
@media (max-width: 480px) {
  .read .readContainer .readWrapper .readSidebarRight {
    width: 100%;
    order: 2;
  }
}
.read .readContainer .readWrapper .readSidebarRight .ritemsStick {
  position: sticky;
  position: -webkit-sticky;
  z-index: 997;
  top: 110px;
}
.read .readContainer .readWrapper .readSidebarRight .ritemsStick .readTableofContents {
  border: 1px solid #0088f0;
  padding: 10px;
  margin-top: 20px;
  box-shadow: rgba(20, 169, 255, 0.3411764706) 0px 10px 15px -3px, rgba(0, 136, 240, 0.5725490196) 0px 4px 6px -2px;
  margin-bottom: 30px;
}
.read .readContainer .readWrapper .readSidebarRight .ritemsStick .readTableofContents h2 {
  margin: 5px 0;
  font-family: "VolteRounded-Bold", Volte Rounded;
  color: var(--text-color);
}
.read .readContainer .readWrapper .readSidebarRight .ritemsStick .readTableofContents ul li {
  padding: 3px 0;
}
.read .readContainer .readWrapper .readSidebarRight .ritemsStick .readTableofContents ul li a {
  color: var(--text-color);
}
.read .readContainer .readWrapper .readSidebarRight .ritemsStick .readSocialIcons {
  padding-bottom: 20px;
  display: flex;
}
.read .readContainer .readWrapper .readSidebarRight .ritemsStick .readSocialIcons a {
  margin: 10px;
}
.read .readContainer .readWrapper .readSidebarRight .ritemsStick .readSocialIcons a .rsocIc {
  padding: 10px;
  background-color: #0088f0;
  border-radius: 50%;
  fill: #fff;
  height: 30px;
  width: 30px;
}

.readContentInner {
  margin-bottom: 20px;
}
.readContentInner h1 {
  font-size: 3rem;
}
.readContentInner h2 {
  font-size: 2rem;
}
.readContentInner h3 {
  font-size: 1.5rem;
}
.readContentInner h4 {
  font-size: 1rem;
}
.readContentInner h5 {
  font-size: 0.8rem;
}
.readContentInner h6 {
  font-size: 0.6rem;
}
.readContentInner h1,
.readContentInner h2,
.readContentInner h3,
.readContentInner h4,
.readContentInner h5,
.readContentInner h6 {
  font-family: "VolteRounded-Bold", Volte Rounded;
  margin-bottom: 10px;
  font-weight: 700;
  color: var(--text-color);
}
@media (max-width: 480px) {
  .readContentInner h1,
  .readContentInner h2,
  .readContentInner h3,
  .readContentInner h4,
  .readContentInner h5,
  .readContentInner h6 {
    font-size: 1.3rem;
  }
}
.readContentInner p {
  font-size: 1.35rem;
  line-height: 2.3rem;
  font-weight: 400;
  margin: 0 0 15px 0;
  color: var(--text-color);
  max-width: initial;
  margin-bottom: 1em;
}
@media (max-width: 480px) {
  .readContentInner p {
    font-size: 0.9rem;
    line-height: 20px;
  }
}
.readContentInner ul,
.readContentInner ol {
  margin-left: 20px;
}
.readContentInner li {
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 400;
  margin: 0 0 15px 0;
  color: var(--text-color);
  max-width: initial;
}
@media (max-width: 480px) {
  .readContentInner li {
    font-size: 0.9rem;
    line-height: 20px;
  }
}

.blog-pre {
  margin-bottom: 3em !important;
  position: relative;
}

.code-copy-btn {
  color: white;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 20px;
  z-index: 10;
}

.code-copy-btn:hover {
  transform: scale(1.1);
  opacity: 0.9;
}

.read p > code,
.read ul > li > strong > code {
  background: rgba(0, 0, 0, 0.2666666667);
  padding: 2px 8px;
  border-radius: 5px;
}
.read ul {
  padding-left: 20px;
}
.read li {
  list-style: circle;
}
.read .read-page-header {
  color: inherit;
}/*# sourceMappingURL=read.css.map */