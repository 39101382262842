.ourbrands {
  .ourbrandsContainer {
    margin: 0 5%;
    .ourbrandsWrapper {
      .ourbrandstitle {
        font-size: 5.375rem;
        line-height: 5rem;
        letter-spacing: -0.05rem;
        color: var(--text-color);
        font-family: 'VolteRounded-Bold', Volte Rounded;
        font-weight: 800 !important;
        margin-bottom: 2rem;
        user-select: none;
        text-align: center;
        @media (max-width: 1900px) {
          font-size: 4rem;
          line-height: 4rem;
        }
        @media (max-width: 480px) {
          font-size: 3rem;
          line-height: 3rem;
        }
      }
      .ourbrandsFlex {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 2rem;
        @media (max-width: 480px) {
          flex-direction: column;
        }
        .ourbrandsCards {
          width: 200px;
          cursor: pointer;
          .ourBrandImageWrapper {
            width: 100%;
            height: 200px;
            position: relative;
            .orbrancIc {
              fill: var(--text-color) !important;
              stroke: var(--text-color) !important;
              color: var(--text-color);
              width: 100%;
              height: 100%;
              margin: 0 auto;
            }
            .tenafricIcsvg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 250px;
            }
            .medgromartIcsvg {
              stroke: none !important;
            }
            .orbrancIcImage {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 80%;
              height: 80%;
              margin: 0 auto;
              @media (max-width: 480px) {
                height: 120px;
                width: 120px;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          .ourbrandTxts {
            text-align: center;
            h1 {
              font-size: 1.4rem;
              margin: 10px 0;
              font-family: 'VolteRounded-Bold', Volte Rounded;
              user-select: none;
              color: var(--text-color);
            }
            p {
              font-size: 1rem;
              font-family: sans-serif, monospace;
              font-weight: 400;
              color: var(--text-color);
            }
          }
        }
      }
    }
  }
}
