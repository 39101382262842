.payment {
  .paymentContainer {
    margin: 0 5%;
    .paymentWrapper {
      .paymentTitle {
        font-size: 5.375rem;
        line-height: 5rem;
        letter-spacing: -0.05rem;
        color: var(--text-color);
        font-family: 'VolteRounded-Bold', Volte Rounded;
        font-weight: 800 !important;
        margin-bottom: 2rem;
        user-select: none;
        text-align: center;
        @media (max-width: 1900px) {
          font-size: 4rem;
          line-height: 4rem;
        }
        @media (max-width: 480px) {
          font-size: 3rem;
          line-height: 3rem;
        }
      }
      .paymentContent {
        .paymentMethod {
          h1 {
            color: var(--text-color);
            margin-bottom: 20px;
          }
          .payment_method_expl {
            color: var(--text-color);
            font-size: 1.4rem;
            margin-bottom: 20px;
            ul {
              margin-left: 20px;
            }
            ul > li {
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
