/*=== Our Blog ===*/
.blog-sec-top {
  text-align: center;
  padding: 4% 20%;
  padding-top: 2% !important;
}
@media (max-width: 480px) {
  .blog-sec-top {
    padding: 3% 5%;
  }
}
.blog-sec-top h1 {
  font-size: 5.375rem;
  line-height: 5rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  font-family: "VolteRounded-Bold", Volte Rounded;
  font-weight: 800 !important;
  margin-bottom: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: center;
}
@media (max-width: 1900px) {
  .blog-sec-top h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (max-width: 480px) {
  .blog-sec-top h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.blog-sec-top p {
  font-size: 1.2rem;
  color: var(--text-color);
}
@media (max-width: 480px) {
  .blog-sec-top p {
    font-size: 0.8rem;
  }
}

.blog-landing .blog-landing-wrapper {
  margin: 20px 5%;
}
.blog-landing .blog-landing-wrapper .spot-blog {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  gap: 1rem;
}
@media (max-width: 480px) {
  .blog-landing .blog-landing-wrapper .spot-blog {
    flex-direction: column;
  }
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-image {
  width: 45%;
  border-radius: 12px;
  overflow: hidden;
}
@media (max-width: 480px) {
  .blog-landing .blog-landing-wrapper .spot-blog .spot-image {
    width: 100%;
    margin-bottom: 10px;
  }
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-texts {
  width: 49%;
  flex: 1;
}
@media (max-width: 480px) {
  .blog-landing .blog-landing-wrapper .spot-blog .spot-texts {
    width: 100%;
  }
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-texts .spot-category {
  padding: 5px;
  border-radius: 6px;
  background-color: rgba(66, 99, 111, 0.7843137255);
  cursor: pointer;
  color: var(--text-color);
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-texts .spot-date {
  text-transform: uppercase;
  margin-left: 10px;
  color: var(--text-color);
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-texts h2 {
  color: #0088f0;
  font-family: "Volte-Rounded-Semibold";
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 300;
  letter-spacing: 0.00625rem;
  margin-top: 1rem;
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  .blog-landing .blog-landing-wrapper .spot-blog .spot-texts h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-texts p a {
  font-size: 1.4rem;
  text-decoration: none;
  color: var(--text-color);
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-texts .spot-author {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-texts .spot-author img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-texts .spot-author .spot-author-name {
  margin-left: 10px;
  color: var(--text-color);
  font-family: "Volte-Rounded-Semibold";
}
.blog-landing .blog-landing-wrapper .spot-blog .spot-texts .spot-author .spot-author-name p {
  font-size: 0.7rem;
  font-family: "Volte-Rounded-Semibold";
  color: var(--text-color);
}
.blog-landing .blog-landing-wrapper .spot-blog-error {
  text-align: center;
}
.blog-landing .blog-landing-wrapper .spot-blog-error img {
  width: 100px;
  height: auto;
  margin: -60px 0 -30px 0;
}
.blog-landing .blog-landing-wrapper .spot-blog-error h1 {
  margin-top: 1rem;
  font-size: 35px;
  font-family: "Fira Code", "Courier New", Courier, monospace;
}
.blog-landing .blog-landing-wrapper .spot-blog-error h1 span {
  font-size: 60px;
}
.blog-landing .blog-landing-wrapper .spot-blog-error p {
  font-family: "Fira Code", "Courier New", Courier, monospace;
  margin-top: 1rem;
}

.blogs-showcase {
  margin: 0 5%;
}
.blogs-showcase .bloghighHeader {
  display: flex;
  margin: 3%;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.blogs-showcase .bloghighHeader .blogsFileters {
  display: flex;
}
@media (max-width: 480px) {
  .blogs-showcase .bloghighHeader .blogsFileters {
    flex-direction: column;
    align-items: flex-start;
  }
}
.blogs-showcase .bloghighHeader .blogsFileters .filter {
  padding: 8px 15px;
  border-radius: 23px;
  font-size: 0.9rem;
  text-decoration: none;
  color: var(--text-color);
  margin-right: 20px;
  cursor: pointer;
}
.blogs-showcase .bloghighHeader .blogsFileters .filter:nth-child(1) {
  margin-left: -20px;
}
.blogs-showcase .bloghighHeader .blogsFileters .filter:hover {
  color: #fff;
  font-weight: 500;
  background-color: #0088f0;
}
@media (max-width: 480px) {
  .blogs-showcase .bloghighHeader .blogsFileters .filter {
    margin-right: 0;
    margin-left: -20px;
    display: none;
  }
  .blogs-showcase .bloghighHeader .blogsFileters .filter:nth-child(1) {
    margin-left: -10px;
    display: unset;
  }
}
.blogs-showcase .bloghighHeader .blogsFileters .activeFilter {
  background-color: #0088f0;
  color: #fff;
  font-weight: 500;
}
.blogs-showcase .bloghighHeader .blogsFileters .activeFilter:hover {
  background-color: #0088f0 !important;
}
.blogs-showcase .bloghighHeader .viewIcon {
  cursor: pointer;
}
.blogs-showcase .bloghighHeader .viewIcon .bgfilterIc {
  fill: var(--text-color);
}
.blogs-showcase .highflex {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4%;
  position: relative;
}
.blogs-showcase .highflex .loadingAnim {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blogs-showcase .highflex .loadingAnim .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.blogs-showcase .highflex .loadingAnim .lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0088f0;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.blogs-showcase .highflex .loadingAnim .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.blogs-showcase .highflex .loadingAnim .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.blogs-showcase .highflex .loadingAnim .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.blogs-showcase .highflex .loadingAnim .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.blogs-showcase .highflex .highblogcard {
  width: 380px;
  overflow: hidden;
  margin-bottom: 30px;
  cursor: pointer !important;
}
.blogs-showcase .highflex .highblogcard .bloghighImage {
  flex: 0 0 100%;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden !important;
}
.blogs-showcase .highflex .highblogcard .bloghighImage img {
  max-height: 250px;
  height: auto !important;
  border-radius: 0;
  box-shadow: initial;
  padding: 0 !important;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  filter: brightness(90%);
  transition: 0.5s;
  vertical-align: middle;
  border-style: none;
}
.blogs-showcase .highflex .highblogcard .bloghighImage img:hover {
  margin-top: 0;
  filter: initial;
  box-shadow: initial;
  transform: scale(1.05);
  transition: 0.5s;
}
.blogs-showcase .highflex .highblogcard .bloghighTitle {
  padding: 10px;
}
.blogs-showcase .highflex .highblogcard .bloghighTitle h2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  cursor: pointer;
  color: var(--text-color);
  font-family: "Volte-Rounded-Semibold";
}
.blogs-showcase .highflex .highblogcard .bloghighTitle h2:hover {
  transition: 0.3s;
  color: #0088f0;
}
.blogs-showcase .highflex .highblogcard .bloghighTitle p {
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  max-height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  color: var(--text-color);
  word-break: break-word;
  cursor: pointer;
}
.blogs-showcase .highflex .highblogcard .bloghighInfo p {
  margin-left: 10px;
  color: var(--text-color);
}
.blogs-showcase .highflex .errFetchBlogs {
  padding: 40px 10px;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.blogs-showcase .highflex .errFetchBlogs p {
  font-size: 4rem;
  font-family: "BookerlyDisplay_W_Rg";
  font-weight: 800 !important;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-left: 20px;
}
@media (max-width: 480px) {
  .blogs-showcase .highflex .errFetchBlogs p {
    font-size: 2rem;
  }
}
.blogs-showcase .highflex .errFetchBlogs p span {
  text-decoration: underline;
  color: #0088f0;
  cursor: pointer;
}/*# sourceMappingURL=blog.css.map */