.mod_services {
  height: min-content;
  .ms_wrapper {
    padding: 20px;
    .ms_wrap_inner_contr {
      margin-bottom: 20px;
      .ms_title {
        font-size: 2rem;
      }
      .ms_wrap_flex_row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
        margin-top: 20px;
        .ms_serv_item {
          padding: 0.7rem;
          border-radius: 5px;
          border: 1px solid #000;
          cursor: pointer;
          h1 {
            font-size: 1.5rem;
          }
        }
      }
      .ms_wrap_flex_runtimes {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 20px;
        margin-top: 20px;
        .ms_serv_item {
          padding: 0.7rem;
          border-radius: 5px;
          border: 1px solid #000;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: unset;
          }
        }
      }
    }
  }
}
