.wksheader {
  height: 54px;
  width: 100%;
  background-color: #bfdbff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  .wksContainer {
    .wksWrapper {
      display: flex;
      padding-top: 5px;
      .wksLeft {
        flex: 1;
        padding-left: 20px;
        .wksImgLogo {
          height: 40px;
          width: 40px;
          cursor: pointer;
        }
      }
      .wksRight {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0 20px;
        .wksHProfile {
          height: 40px;
          width: 40px;
          background-color: #fff;
          border-radius: 50%;
          overflow: hidden;
        }
        .wksLogoutIc {
          height: 20px;
          width: 20px;
          fill: var(--text-color);
          cursor: pointer;
        }
      }
    }
  }
}
