.header {
  position: relative;
  z-index: 998;
  .hcontainer {
    margin: 0 5%;
    @media (max-width: 480px) {
      margin: 0;
      display: flex;
      justify-content: space-between;
    }
    .mobiNavBars {
      height: 30px;
      width: 30px;
      margin: 20px;
      fill: var(--text-color);
      @media (min-width: 480px) {
        display: none;
      }
    }
    .mobiheaderright {
      display: flex;
      align-items: center;
      @media (min-width: 480px) {
        display: none;
      }
      .ctahheadersignin {
        all: unset;
        padding: 10px 30px;
        background-color: #fff;
        background: linear-gradient(
          to bottom right,
          #14a9ff,
          #0088f0
        ) !important;
        border-radius: 43px;
        text-align: center;
        color: #fff;
        cursor: pointer;
        margin-right: 15px;
        font-family: 'VolteRounded-Bold' !important;
        @media (max-width: 480px) {
          margin-right: unset !important;
          bottom: 80px;
        }
      }
      .Mobiheaderlogo {
        width: 40px;
        margin: 10px 20px;
        .MobiheaderLogoLink {
          width: 30px;
        }
      }
    }
    .hwrapper {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 999;
      @media (max-width: 480px) {
        display: none;
      }
      .hleft {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 480px) {
          flex-direction: column;
          align-items: unset;
          order: 1;
        }
        .hbranding {
          .headerlogo {
            width: 50px;
            @media (max-width: 480px) {
              margin: 20px 40px;
            }
            .headerLogoLink {
              width: 50px;
            }
          }
        }
        .headerNavigation {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: -10px;
          .headerLink {
            padding: 10px 15px;
            margin-bottom: -10px;
            position: relative;
            .header_link_anchor {
              font-size: 1.2rem;
              font-weight: 300;
              font-family: Apercu, Roboto;
              color: var(--text-color);
              user-select: none;
              white-space: nowrap;
            }
            .dropdown-content {
              position: absolute;
              margin-top: 18px;
              // height: 300px;
              // width: 500px;
              z-index: 1000;
              box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
                rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
              background-color: var(--background-header);
              border-radius: 5px;
              padding: 5px;
              &::after {
                content: '';
                position: absolute;
                z-index: 1002;
                top: -19px;
                left: 10px;
                width: 0;
                height: 0;
                transform: rotate(180deg);
                border-left: 14px solid transparent;
                border-right: 14px solid transparent;
                border-top: 20px solid var(--background-modals);
              }
              .dropdownwrapper {
                .dropdownleft {
                  .droplinks {
                    .dropdLink {
                      padding: 10px;
                      white-space: nowrap;
                      color: var(--text-color);
                      cursor: pointer;
                      &:hover {
                        color: #0088f0;
                      }
                    }
                  }
                }
              }
            }
          }
          .headerBrandAware {
            background: linear-gradient(to left, #743ad5, #d53a9d);
            padding: 5px 10px;
            border-radius: 15px;
            margin-bottom: -10px;
            p {
              white-space: nowrap;
              color: #fff;
            }
          }
        }
      }
      .hright {
        margin-top: -10px;
        .header-right {
          .header-right-flex {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .ctahheadersignin {
              all: unset;
              padding: 10px 30px;
              background-color: #fff;
              background: linear-gradient(
                to bottom right,
                #14a9ff,
                #0088f0
              ) !important;
              border-radius: 43px;
              text-align: center;
              color: #fff;
              cursor: pointer;
              margin-right: 15px;
              font-family: 'VolteRounded-Bold' !important;
            }
            .header-search {
              margin-bottom: -2px;
              position: relative;
              .searchLensh {
                fill: var(--text-color);
                cursor: pointer;
                height: 18px;
                width: 18px;
                &:hover {
                  fill: var(--text-color);
                }
              }
              .dropdown-content {
                position: absolute;
                top: 26px;
                right: 0px;
                margin-top: 14px;
                // height: 300px;
                // width: 500px;
                z-index: 1000;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
                  rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                background-color: var(--background-header);
                border-radius: 5px;
                padding: 5px;
                &::after {
                  content: '';
                  position: absolute;
                  z-index: 1002;
                  top: -19px;
                  right: 10px;
                  width: 0;
                  height: 0;
                  transform: rotate(180deg);
                  border-left: 14px solid transparent;
                  border-right: 14px solid transparent;
                  border-top: 20px solid var(--background-modals);
                }
                .dropdownwrapper {
                  .dropdownleft {
                    .droplinks {
                      .dropdLink {
                        padding: 10px;
                        white-space: nowrap;
                        color: var(--text-color);
                        cursor: pointer;
                        &:hover {
                          color: #0088f0;
                        }
                      }
                    }
                  }
                }
              }
            }

            .header-right-links {
              margin-left: 20px;
              display: flex;
              align-content: center;
              align-items: center;
              a {
                margin-right: 15px;

                .header-mail-icon {
                  fill: var(--text-color);
                  cursor: pointer;
                  height: 18px;
                  width: 18px;
                  &:hover {
                    fill: var(--text-color);
                  }
                }
              }

              .theme-controls {
                position: relative;
                margin-top: -23px;
                svg {
                  stroke: var(--text-color);
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                  height: 18px;
                  width: 18px;
                  &:hover {
                    stroke: var(--text-color);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.mobileNavigation {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: linear-gradient(
    180deg,
    var(--background-color-one) 0%,
    var(--background-color-two) 20%,
    var(--background-color-three) 100%
  );
  transition: all 0.3s ease-in-out 0s;
  margin-left: -100vh;
  @media (min-width: 480px) {
    display: none;
  }
  .mnavWrapper {
    width: 100%;
    .mnavtop {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      .mobiheaderLeft {
        display: flex;
        align-items: center;
        .Mobiheaderlogo {
          width: 40px;
          margin: 10px 20px;
          .MobiheaderLogoLink {
            width: 30px;
          }
        }
        .mhLUser {
          .usrmhIcsh {
            fill: var(--text-color);
            height: 40px;
            width: 40px;
          }
        }
        .ctahheadersignin {
          all: unset;
          padding: 10px 30px;
          background-color: #fff;
          background: linear-gradient(
            to bottom right,
            #14a9ff,
            #0088f0
          ) !important;
          border-radius: 43px;
          text-align: center;
          color: #fff;
          cursor: pointer;
          margin-right: 15px;
          font-family: 'VolteRounded-Bold' !important;
          @media (max-width: 480px) {
            margin-right: unset !important;
            bottom: 80px;
          }
        }
      }
      .mobiNavTimes {
        height: 30px;
        width: 40px;
        fill: #0088f0;
        cursor: pointer;
      }
    }
    .mobinavbtm {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      .mnavLink {
        padding: 15px 20px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-size: 17px;
        line-height: 22px;
        color: var(--text-color);
        margin: 0px;
        padding-bottom: 9px;
        border-bottom: 1px solid #e3e3e3;
      }
    }
    .mhbtmlnks {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 20px;
      .mhbtmanhr {
        .header-mail-icon {
          fill: var(--text-color);
          cursor: pointer;
          height: 18px;
          width: 18px;
          &:hover {
            fill: var(--text-color);
          }
        }
      }
      .theme-controls {
        svg {
          stroke: var(--text-color);
          cursor: pointer;
          height: 18px;
          width: 18px;
          &:hover {
            stroke: var(--text-color);
          }
        }
      }
    }
  }
}
.hidemobinav {
  @media (max-width: 480px) {
    margin-left: -100vh;
    animation: slideOut 1s forwards;
  }
}
.openmobinav {
  @media (max-width: 480px) {
    animation: slideIn 1s forwards;
  }
}
@keyframes slideIn {
  0% {
    margin-left: -100vw;
  }
  100% {
    margin-left: 0vw;
  }
}
@keyframes slideOut {
  0% {
    margin-left: 0vw;
  }
  100% {
    margin-left: -100vw;
  }
}
.sticky_header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-color: var(--background-header);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  opacity: 1;
  backdrop-filter: blur(10px);
  ~ .landingWrapper {
    padding-top: 54px !important;
  }
  .hcontainer {
    margin: 0 5%;
    .hwrapper {
      padding: 10px !important;
      padding-top: 11.5px !important;
      .hleft {
        .hbranding {
          .headerlogo {
            width: 30px;
            .headerLogoLink {
              width: 50px;
            }
          }
        }
      }
    }
    .hright {
      margin-top: -5px !important;
    }
  }
  @keyframes fadeInDown {
    0% {
      top: -70px;
    }

    100% {
      top: 0;
    }
  }
}
