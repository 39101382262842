.search {
  height: 100vh;
}
.search .serContainer {
  height: 100%;
}
.search .serContainer .serWrapper {
  height: 100%;
  position: relative;
}
.search .serContainer .serWrapper .serForm {
  position: absolute;
  top: 20%;
  width: 100%;
}
.search .serContainer .serWrapper .serForm .serFormGroup {
  position: relative;
  width: calc(100% - 20px);
  width: 50%;
  margin: 0 auto;
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}
.search .serContainer .serWrapper .serForm .serFormGroup .serInput {
  padding: 10px;
  width: 90%;
  outline: none;
  border: none;
  color: var(--text-color);
  background-color: rgba(255, 255, 255, 0);
  font-size: 2rem;
}
.search .serContainer .serWrapper .serForm .serFormGroup .serFLensIc {
  position: absolute;
  right: 20px;
  top: 10px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  fill: #6a6a6a;
}
.search .serContainer .serWrapper .serResults {
  position: absolute;
  top: 40%;
  padding: 20px;
  margin: 0 5%;
}/*# sourceMappingURL=search.css.map */