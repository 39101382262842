@use 'main/fonts.scss';
@use 'main/variables.scss';

*,
::after,
::before {
  position: relative;
  padding: 0;
  margin: 0;
  border-style: border-box;
  -webkit-font-smoothing: inherit;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

html,
body {
  text-size-adjust: 100%;
  scroll-behavior: smooth;
  font-family: 'Volte-Rounded-Regular', Volte Rounded, Apercu, Roboto, Helvetica,
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: none;
  direction: ltr;
  transition: background 2s;
  position: relative;
}

.app {
  background: linear-gradient(
    180deg,
    var(--background-color-one) 0%,
    var(--background-color-two) 20%,
    var(--background-color-three) 100%
  );
  transition: all 0.3s ease-in-out 0s;
}

/* Remove increment and decrement buttons from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background: linear-gradient(
    180deg,
    var(--background-color-one) 0%,
    var(--background-color-two) 20%,
    var(--background-color-three) 100%
  ) !important;
  transition: all 0.3s ease-in-out 0s;
  color: fieldtext !important;
}

//loading animation
/**
 * ==============================================
 * dot-flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 0 auto;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #ffffff;
  }
  50%,
  100% {
    background-color: #fff3f333;
  }
}

@keyframes loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.c-skeleton-line,
.c-skeleton-circle,
.c-skeleton-square {
  background-color: #c2c2ff;
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  opacity: 0.9;
}

.c-skeleton-line:after,
.c-skeleton-circle:after,
.c-skeleton-square:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  //background-image: linear-gradient(90deg, #494949, #6b6b6b, #494949);
  background-image: linear-gradient(90deg, #c2c2ff, #d2d2ff, #c2c2ff);
  transform: translateX(-100%);
  animation-name: loading-skeleton;
  animation-direction: normal;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
