.payment .paymentContainer {
  margin: 0 5%;
}
.payment .paymentContainer .paymentWrapper .paymentTitle {
  font-size: 5.375rem;
  line-height: 5rem;
  letter-spacing: -0.05rem;
  color: var(--text-color);
  font-family: "VolteRounded-Bold", Volte Rounded;
  font-weight: 800 !important;
  margin-bottom: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: center;
}
@media (max-width: 1900px) {
  .payment .paymentContainer .paymentWrapper .paymentTitle {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (max-width: 480px) {
  .payment .paymentContainer .paymentWrapper .paymentTitle {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.payment .paymentContainer .paymentWrapper .paymentContent .paymentMethod h1 {
  color: var(--text-color);
  margin-bottom: 20px;
}
.payment .paymentContainer .paymentWrapper .paymentContent .paymentMethod .payment_method_expl {
  color: var(--text-color);
  font-size: 1.4rem;
  margin-bottom: 20px;
}
.payment .paymentContainer .paymentWrapper .paymentContent .paymentMethod .payment_method_expl ul {
  margin-left: 20px;
}
.payment .paymentContainer .paymentWrapper .paymentContent .paymentMethod .payment_method_expl ul > li {
  padding-bottom: 10px;
}/*# sourceMappingURL=paymentMethods.css.map */