/* ======newsletter subscribe stylings====== */
.secNewsletter {
  padding-top: 150px;
  padding-bottom: 150px;
}
.secNewsletter .newsletter .newslettercontainer {
  margin: 0 5%;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter {
  background-size: 40%, 60%;
  background-position: left top, right bottom;
  background-image: url(../../assets/svg/glow-blue-02.svg), url(../../assets/svg/glow-purple-02.svg);
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  max-width: 1216px;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex {
  display: flex;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  border-radius: 0.75rem;
  background-color: #09141a;
  background-position: 100%;
  background-repeat: no-repeat;
  flex-direction: row;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  background-image: url(../../assets/svg/envelop.svg);
}
@media (max-width: 480px) {
  .secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex {
    flex-direction: column;
    padding: 2rem !important;
    grid-gap: unset;
    gap: unset;
  }
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subNewstexts {
  width: 50%;
}
@media (max-width: 480px) {
  .secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subNewstexts {
    width: 100%;
  }
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subNewstexts .subNewstitle {
  font-size: 2rem;
  line-height: 2.25rem;
  color: #e6e6e6;
  font-weight: 700;
  letter-spacing: 1.5px;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subNewstexts .subNewsTextsp {
  margin-top: 0.75rem;
  color: #e6e6e6;
  letter-spacing: 0.5px;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform {
  position: relative;
  width: 50%;
}
@media (max-width: 480px) {
  .secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform {
    width: 100%;
    margin: 40px 0 20px 0;
  }
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 480px) {
  .secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf {
    width: 100%;
  }
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf .subinputsFlex {
  display: flex;
  overflow: hidden;
  border-radius: 0.75rem;
}
@media (max-width: 480px) {
  .secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf .subinputsFlex {
    width: 100%;
  }
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf .subinputsFlex .subnewsinput {
  outline: none;
  border: none;
  padding: 10px 1rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  background-color: rgba(49, 49, 49, 0.707);
  color: #ffffff;
  width: 300px;
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
}
@media (max-width: 480px) {
  .secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf .subinputsFlex .subnewsinput {
    width: 100%;
    font-size: 0.9rem;
  }
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf .subinputsFlex .subnewsButton {
  all: unset;
  padding: 10px;
  background-color: #fff;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin-right: 15px;
  font-family: "VolteRounded-Bold" !important;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
}
@media (max-width: 480px) {
  .secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf .subinputsFlex .subnewsButton {
    font-size: 0.9rem;
  }
}/*# sourceMappingURL=newsletter.css.map */